import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Row, Col, Alert, DropdownButton, Dropdown, Toast } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/aulia.aac'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoigwhite from '../assets/img/aulia/logoigwhite.svg'
import logoig from '../assets/img/aulia/logoig.svg'
import burung from '../assets/img/aulia/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import getData from '../params/getdata'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

let cmain = '#69785b'
let black = 'rgb(38,38,38)'
let id = 'shelly-yosua'
let inisial_co = 'Yosua'
let inisial_ce = 'Shelly'
let lengkap_co = 'Yosua Martin Sutikno Sinaga, S.E'
let lengkap_ce = 'Shelly Christine Adolina Siregar, S.E'
let bapak_co = 'Mr. Sanggam Sinaga (+)'
let ibu_co = 'Mrs. Murni Lumbantoruan'
let bapak_ce = "Mr. Sihar Leonardo Siregar (+) "
let ibu_ce = "Mrs. Lydia Basanauli Harahap"
let ig_co = ""
let ig_ce = ""

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: false,
            err: [],
            submitted: '',
            sesi: 1,
            comment: [],
            friend: null,
            show: false,
            show1: false,
            showgift: false,
            shownew: false
        }
    }

    dropdown = () => {
        let { friend } = this.state
        let item = []
        for (let index = 0; index < 3; index++) {
            item[index] = (<Dropdown.Item onClick={() => {
                this.setState({ friend: index })
            }}>{index}</Dropdown.Item>)
        }
        return (
            <>
                <Item>
                    <p style={{ fontSize: '16px' }} className="mb-0 w-100 text-center">
                        How many people you coming with?
      </p>
                    <DropdownButton id="dropdown-basic-button" title={friend == null ? '1' : friend}>
                        {
                            item
                        }
                    </DropdownButton>
                </Item>
            </>
        )
    }

    componentDidMount = async () => {
        try {
            AOS.init({
                // initialise with other settings
                duration: 2000
            });
            var countDownDate = new Date("01/30/2021").getTime();

            // Update the count down every 1 second
            var x = setInterval(() => {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                this.setState({
                    days: days, hours: hours, minutes: minutes, seconds: seconds
                })
            }, 1000);


            let comment = await getData(`kepada:"${id}"`)
            this.setState({ comment: comment })
        } catch (error) {
            console.log(error)
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw("yesica-youngky", "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.friend}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showgift, comment, shownew, sesi } = this.state
        let slide = [
            "DSC05835.jpg",
            "DSC05879.jpg",
            "DSC05884.jpg",
            "DSC05908.jpg",
            "DSC05985.jpg",
            "DSC06018.jpg",
            "DSC06162.jpg",
            "DSC06187.jpg",
            "DSC06195.jpg",
            "DSC06245.jpg",
            "DSC06264.jpg",
            "DSC06308.jpg",
            "DSC06333.jpg",
        ]
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundImage: `url(${gambar('https://i.pinimg.com/originals/ce/fc/80/cefc801a4c1d6fe986e225e2182275af.jpg', 95, 'auto&blur=8&bright=20')})`,
                    backgroundSize: '100%',
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${pw(id, "modal.jpg")}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-2'
                                        src={gambar(pw(id, "logo.png"))} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kindly Deliver to Mr. / Mrs. / Ms.
                                        <br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button roboto-slab text-center btn ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                  </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                {/* <Container >
                                    <Item >
                                        <Col xs={10} md={6}>
                                            <img src={gambar('https://i.ibb.co/cFtJ3sY/Logo-Aulia-Dalam.png')} className='w-100 img-fluid' />
                                        </Col>
                                    </Item>
                                </Container> */}
                                <Container className="dinny px-4 pt-2 ">
                                    <Item>

                                        <p className="fs24 text-center cblack">
                                            <span className="fs16">
                                                Matius 19:6<br />
Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.</span>
                                        </p>
                                    </Item>
                                    <Item>
                                        <Col xs={6} md={4}>
                                            <img src='https://webstockreview.net/images/clipart-flower-hipster-2.png' data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                    <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                        <iframe className="embed-responsive-item"
                                            src="https://www.youtube.com/embed/c5DvILvpiuA"></iframe>
                                    </div>
                                </Container>
                                <Container id='sectiongold57' className="py-3">
                                    <div className='pt-3'>
                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Days
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Hours
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div >
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Mins
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' >:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Secs
                      </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div></Container>

                                <Container id='sectiongold55' className="py-5 aulia" fluid style={{ backgroundColor: cmain }}>
                                    <Row style={{ backgroundColor: 'white' }} className="py-3">
                                        <Col xs={12} md={6} className="px-3 py-md-5 py-2">
                                            <img src={'https://i.ibb.co/z2HsWyC/DSC06018.jpg'} className="w-100 img-fluid" />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Item>
                                                <h1 style={{ fontSize: '36px', color: cmain, lineHeight: 1.2, fontFamily: 'Mr De Haviland, cursive' }}
                                                    className="w-100 text-center"
                                                >
                                                    <b>{lengkap_ce}</b>
                                                </h1>
                                            </Item>
                                            <Item>
                                                <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                                                    <b>the First Daugther of: </b><br />
                                                    {bapak_ce}<br />
                                                        &<br />
                                                    {ibu_ce}
                                                </p>
                                            </Item>

                                            <Item>
                                                <h1 style={{
                                                    fontSize: '36px', color: cmain,
                                                    lineHeight: 1.2, fontFamily: 'Mr De Haviland, cursive'
                                                }}
                                                    className="py-4" >
                                                    <b>&</b>
                                                </h1>
                                            </Item>
                                            <Item>
                                                <h1 style={{ fontSize: '36px', color: cmain, lineHeight: 1.2, fontFamily: 'Mr De Haviland, cursive' }}
                                                    className="w-100 text-center"
                                                >
                                                    <b>{lengkap_co}</b>
                                                </h1>
                                            </Item>

                                            <Item>
                                                <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)', fontStyle: 'normal' }}>
                                                    <b>the First Son of: </b><br />
                                                    {bapak_co}
                                                    <br />
                                                     &<br />
                                                    {ibu_co}
                                                </p>
                                            </Item>

                                        </Col>


                                    </Row>
                                </Container>
                                <div style={{
                                    backgroundImage: `url(${gambar('https://i.pinimg.com/originals/ce/fc/80/cefc801a4c1d6fe986e225e2182275af.jpg', 95, 'auto&blur=8&bright=20')})`,
                                    backgroundSize: '100%',
                                }}>


                                    <Container fluid className="text-center px-4 dinny py-3" style={{ color: black }} >
                                        <Item>
                                            <p className="fs16">
                                                By The Love and The Grace of Our God We Cordially Request The Honour Of Your Presence at Our Wedding
                                            </p>
                                        </Item>
                                        <Item>
                                            <p className="fs20">
                                                <b>
                                                    Saturday, January 30th 2021
                                                </b>
                                            </p>
                                        </Item>
                                        <Item>
                                            <p className="fs20 col-md-4" style={{ color: cmain }}>
                                                <div className="butterfly"
                                                    style={{ fontStyle: 'bold', fontSize: '36px' }}>
                                                    <b>Holy Matrimony </b><br /></div>
                                                <span className="cblack fs16">
                                                    GKI Raya Hankam
                                                        </span><br />
                                            </p>
                                            <p className="px-3 d-none d-md-block" style={{ color: cmain, fontSize: '72px' }}>
                                                \
                                            </p>
                                            <div className="col-8 d-md-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                            </div>
                                            <p className="fs20 pt-3 pt-md-0 col-md-4" style={{ color: cmain }}>
                                                <div className="butterfly" style={{ fontSize: '36px' }}><b>Wedding Reception</b><br />
                                                </div>
                                                <span className="cblack fs16" style={{ fontStyle: 'bold' }}>
                                                    Sejahtera Meeting Building<br />
                                                at 12.00
                      </span> <br />
                                                <span className="cblack fs12">

                                                </span>
                                            </p>
                                        </Item>
                                        {/* <Item>
                                            <p className="fs16 pt-3">
                                                <img src='https://www.flaticon.com/svg/static/icons/svg/2928/2928883.svg' className='w-25 px-5 img-fluid' /><br />                    <span >
                                                    <b>
                                                        Sejahtera Meeting Building
                                                </b><br />
                                                 Jl. Raya Pondok Gede No.56, Lubang Buaya, Cipayung, Jakarta Timur
                    </span>
                                            </p>
                                        </Item> */}
                                        {/* <Item>
                                            <Col xs={12} md={8}>
                                                <img src={gambar('https://i.ibb.co/CVS12YR/Denah-Lokasi.png', 90)} className="img-fluid w-100" />
                                            </Col>
                                        </Item> */}
                                        <Item>
                                            <Col xs={10} md={3}
                                                style={{
                                                    border: `2px solid ${cmain}`,
                                                    borderRadius: '10px'
                                                }}
                                                onClick={() => {
                                                    window.open("https://goo.gl/maps/1TWnKHkAWx5YTqzR6")
                                                }}
                                                className="btn p-2 mx-md-2">
                                                <Item>
                                                    <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                        style={{ width: "10%", height: '10%' }} />
                                                    <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                        <b>Get Direction</b>
                                                    </p>
                                                </Item>
                                            </Col>
                                            <Col
                                                onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=MDdhbmNncTRuaW0ydThvdjExZTFqa2ZpcDQgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                                                xs={10} md={3}
                                                style={{
                                                    border: `2px solid ${cmain}`,
                                                    borderRadius: '10px'
                                                }}
                                                className="btn p-2 mx-md-2 mt-3 mt-md-0">
                                                <Item>
                                                    <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                        style={{ width: "10%", height: '10%' }} />
                                                    <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                        <b>Add to Calendar</b>
                                                    </p>
                                                </Item>
                                            </Col>
                                        </Item>
                                        <Container className="py-3">
                                            <Item>
                                                <Col
                                                    onClick={() => this.setState({ shownew: !shownew })}
                                                    xs={11} md={4}
                                                    style={{
                                                        border: `2px solid ${cmain}`,
                                                        borderRadius: '10px'
                                                    }}
                                                    className="btn p-2 mx-2 mt-3 mt-md-0">
                                                    <Item>
                                                        <img src="https://www.flaticon.com/svg/static/icons/svg/2750/2750740.svg" className="img-fluid"
                                                            style={{ width: "10%", height: '10%' }} />
                                                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                            <b>New Normal Rules</b>
                                                        </p>
                                                    </Item>
                                                </Col>
                                            </Item>
                                        </Container>

                                        <Container className={`text-center dinny pb-3  ${!shownew ? 'trsnone' : 'trs'}`}>
                                            <Container className="py-3">
                                                <Item>
                                                    <Col xs={12} md={6}>
                                                        <img src={pw("asset", "covid-green.svg")} className="w-100 img-fluid" />
                                                    </Col>
                                                </Item>
                                            </Container>
                                        </Container>

                                    </Container>

                                    <Container className='mt-3 py-3 ' data-aos="fade-right" data-aos-duration="1000">
                                        <Item>
                                            <div className="butterfly">
                                                <h1 style={{ color: cmain, fontStyle: 'bold', fontWeight: 700, fontSize: '72px' }}>
                                                    The Moment
                        </h1>
                                            </div>
                                        </Item>
                                        {/* <Item>
                                            <Col className="py-3">
                                                <img src={gambar('https://i.ibb.co/SsxtzY7/image857.png', 95)} className="w-100  img-fluid" />
                                            </Col>
                                        </Item> */}
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <img src={pw(id, "DSC05879.jpg")} className="w-100 img-fluid p-1" />
                                            </Col>
                                            <Col xs={12} md={4} >
                                                <img src={pw(id, "DSC05835.jpg")} className="w-100 img-fluid p-1" />
                                                <img src={pw(id, "DSC05884.jpg")} className="w-100 img-fluid p-1" />
                                            </Col>
                                            <Col xs={12} md={4} >
                                                <img src={pw(id, "DSC05985.jpg")} className="w-100 img-fluid p-1" />
                                                <img src={pw(id, "DSC06018.jpg")} className="w-100 img-fluid p-1" />
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col xs={12} md={4} >
                                                <img src={pw(id, "DSC06195.jpg")} className="w-100 img-fluid p-1" />
                                                <img src={pw(id, "DSC06245.jpg")} className="w-100 img-fluid p-1" />
                                            </Col>
                                            <Col xs={12} md={4} >
                                                <img src={pw(id, "DSC06308.jpg")} className="w-100 img-fluid p-1" />
                                                <img src={pw(id, "DSC06333.jpg")} className="w-100 img-fluid p-1" />
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <img src={pw(id, "DSC05908.jpg")} className="w-100 img-fluid p-1" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <img src={pw(id, "DSC06187.jpg")} className="w-100 img-fluid p-1" />
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <img src={pw(id, "DSC06264.jpg")} className="w-100 img-fluid p-1" />
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <img src={pw(id, "DSC06162.jpg")} className="w-100 img-fluid p-1" />
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container id='sectiongold56' className="py-3 px-4" >
                                        <div className='pt-3'>
                                            <div data-aos={`fade-right`} data-aos-duration="2000">
                                                <Item>
                                                    <div className='kotak col-12 position-relative' style={{ backgroundColor: 'white', overflow: 'hidden' }}>
                                                        <div className="position-absolute w-50" style={{
                                                            top: 0, left: 0, zIndex: 0, opacity: 0.5
                                                        }}>
                                                            <img src={gambar('https://i.ibb.co/hyV37Bg/Daun-daunan.png', 40)} className="w-100 img-fluid" />
                                                        </div>
                                                        <div className="position-absolute w-50" style={{
                                                            top: 0, right: 0, zIndex: 0, opacity: 0.5, transform: 'scaleX(-1)'
                                                        }}>
                                                            <img src={gambar('https://i.ibb.co/hyV37Bg/Daun-daunan.png', 40)} className="w-100 img-fluid" />
                                                        </div>
                                                        <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                                            <iframe className="embed-responsive-item"
                                                                src="https://www.youtube.com/embed/z136orqRSFE"></iframe>
                                                        </div>
                                                        <Item>
                                                            <p className='text-center p-2 px-2 pt-5 amatic' style={{ zIndex: 1, color: 'black' }}>
                                                                The greatest form of happiness is the blend of two hearts that love each other forever united in Marriage
                              </p>
                                                        </Item>
                                                    </div>
                                                </Item>
                                            </div>
                                        </div>
                                    </Container>
                                </div>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-12 col-lg-10 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: 'white'
                                                    }}>
                                                        Send Your Wishes
                 </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={12} md={6}>
                                                        <form className="col-12 w-100">
                                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                            {
                                                                this.dropdown()
                                                            }
                                                            <Item>
                                                                <Col xs={12} className=''>
                                                                    {
                                                                        submitted == true ? (
                                                                            <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                Pesan anda sudah disampaikan
                                                                            </Alert>) : (submitted === false ? (
                                                                                <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                    {
                                                                                        err.map(val => {
                                                                                            return (
                                                                                                <li>{val}</li>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </Alert>
                                                                            ) : false)
                                                                    }

                                                                </Col>
                                                            </Item>
                                                            <Item>
                                                                <div className='col-6 button rounded btn'
                                                                    onClick={() => this.handleSubmit()} style={{ backgroundColor: '#86835d', color: 'white' }} no> Kirim </div>
                                                            </Item>
                                                        </form>

                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <div className='col-12 kotak pb-4 pt-4 mt-3 mt-md-0' data-aos="left-left" data-aos-duration="1000" style={{ height: '500px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                            {comment ? comment.map(v => {
                                                                return (
                                                                    <Item>
                                                                        <Col xs={2}>
                                                                            <img src='https://www.flaticon.com/svg/static/icons/svg/3447/3447670.svg' className="img-fluid w-100" />
                                                                        </Col>
                                                                        <Col xs={10}>
                                                                            <Alert variant="secondary col-12">
                                                                                <p style={{ fontSize: '16px' }} className='m-0'>
                                                                                    {decodeURI(v.pesan)}
                                                                                    <br />
                                                                                    <span style={{ fontStyle: 'italic' }}>
                                                                                        <b>
                                                                                            {decodeURI(v.dari)}
                                                                                        </b>
                                                                                    </span>
                                                                                </p>
                                                                            </Alert>
                                                                        </Col>
                                                                    </Item>
                                                                )
                                                            }) : false

                                                            }
                                                        </div>
                                                    </Col>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container className="py-3" fluid >
                                    <Item>
                                        <Col
                                            onClick={() => this.setState({ showgift: !showgift })}
                                            xs={10} md={4}
                                            style={{
                                                border: `2px solid #86835d`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-md-2 mt-3 mt-md-0">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: '#86835d' }}>
                                                    <b>Send Gift (click here)</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                <Container fluid
                                    className={`text-center mt-3 aulia-wedding py-3 px-3 ${!showgift ? 'trsnone' : 'trs'}`} >

                                    <Col className="py-3 px-md-5 mx-2 w-100 text-center" style={{ backgroundColor: cmain, borderRadius: '10px', color: '#86835d' }}>
                                        <h1 style={{ color: 'white' }} className="w-100 text-center">
                                            Wedding Gift
                      </h1>
                                        <p className="text-center w-100" style={{ color: 'white' }}>
                                            BCA-6871163551<br />
                            (Yosua Martin Sutikno)
                          </p>
                                        <Item>
                                            <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                                                <Toast.Body>Copied!</Toast.Body>
                                            </Toast>
                                        </Item>
                                        <Item>
                                            <CopyToClipboard text="6871163551">
                                                <Col xs={8} className='btn button mb-5 mt-1'
                                                    onClick={(e) => {
                                                        this.setState({ show: true })
                                                    }}>
                                                    Salin No. Rekening
                          </Col>
                                            </CopyToClipboard>
                                        </Item>
                                        <p className="text-center w-100" style={{ color: 'white' }}>
                                            Mandiri-0700009726121  <br />
                            (Shelly Christine Adolina)
                          </p>
                                        <Item>
                                            <Toast onClose={() => this.setState({ show1: false })} show={this.state.show1} delay={3000} autohide >
                                                <Toast.Body>Copied!</Toast.Body>
                                            </Toast>
                                        </Item>

                                        <Item>
                                            <CopyToClipboard text="0700009726121">
                                                <Col xs={8} className='btn button mb-5 mt-1'

                                                    onClick={(e) => {
                                                        this.setState({ show1: true })
                                                    }}>
                                                    Salin No. Rekening
                                                </Col>
                                            </CopyToClipboard>
                                        </Item>



                                    </Col>



                                </Container>



                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

